import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/lib/Async'
import Help from '../Help'
import { withContext } from '../../context/withContext'
import { GetUsers } from '../../api/core'

function SearchableContact(props) {
    const [agents, setAgents] = useState([])
    const [loading] = useState(false)
    const [value, setValue] = useState(props.isMulti ? [] : undefined)
    const [debounce, setDebounce] = useState(0)

    useEffect(() => {
        return () => {
            if (props.defaultValue) setValue(props.defaultValue)
            // let token = props.context.token
            // GetUsers(token, '')
            //     .then(response => {
            //         setAgents(response.data)
            //     })
        }
    }, [])

    useEffect(() => {
        if (props.defaultValue) setValue(props.defaultValue)
    }, [props.defaultValue])

    const handleChange = (element) => {
        if (props.isMulti) {
            let arr = []
            for (let x in element) {
                arr.push(element[x])
            }
            setValue(element)
            if (props.onChange) props.onChange(arr)
        } else {
            setValue(element)
            if (props.onChange && element !== null) {
                props.onChange(element.value)
            } else {
                props.onChange(null)
            }
        }
    }

    const handleChangeFull = (element) => {
        if (props.isMulti) {
            let arr = []
            for (let x in element) {
                arr.push(element[x])
            }
            setValue(element)
            if (props.onChange) props.onChange(arr)
        } else {
            setValue(element)
            if (props.onChange && element !== null) {
                props.onChange(element)
            } else {
                props.onChange(null)
            }
        }
    }

    const loadOptions = (inputValue, callback) => {
        let qs = '?full_name=' + inputValue
        let arr = []
        let token = props.context.token
        clearTimeout(debounce)
        setDebounce(
            setTimeout(() => {
                return GetUsers(token, qs).then((response) => {
                    setAgents(response.data)
                    for (let x = 0; x < response.data.length; x++) {
                        let data = response.data[x]
                        arr.push({ label: data.full_name, value: data.uid })
                    }
                    return callback(arr)
                })
            }, 500)
        )
    }

    return (
        <div className={props.sm ? 'form-group-sm' : 'form-group'}>
            {props.label ? (
                <label htmlFor={props.id}>
                    {props.label}: {props.required ? <span className="danger">*</span> : null}
                </label>
            ) : null}
            {props.help ? <Help id={props.id} help={props.help} /> : null}
            <AsyncSelect
                isMulti={props.isMulti}
                isClearable={props.isClearable}
                value={value}
                loadedOptions={props.defaultValue}
                defaultOptions
                loadOptions={loadOptions}
                isLoading={loading}
                placeholder={props.placeholder ? props.placeholder : 'Seleccionar'}
                isDisabled={props.isDisabled ? props.isDisabled : loading}
                onChange={props.fullVal ? handleChangeFull : handleChange}
                classNamePrefix="react-select"
            />
        </div>
    )
}
export default withContext(SearchableContact)
