import React from 'react'
import { Route } from 'react-router-dom'
// Local import
import asyncComponent from '../../components/asyncComponent'
const View = asyncComponent(() => import('./ViewProgress'))

function Progress(props) {
    return (
        <div className="container-fluid">
            <Route path="/progress" exact component={View} />
        </div>
    )
}
export default Progress
