import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/lib/Async';
import Help from '../Help';
import { withContext } from '../../context/withContext';
import { GetSectorsPaginated } from '../../api/core';

function SearchableSectors(props) {
    const [loading] = useState(false)
    const [value, setValue] = useState(props.isMulti ? [] : undefined)
    const [debounce, setDebounce] = useState(0)
    useEffect(() => {
        return () => {
            if (props.defaultValue) setValue(props.defaultValue)
        };
    }, [])

    useEffect(() => {
        if (props.defaultValue) setValue(props.defaultValue)
    }, [props.defaultValue])


    const handleChange = (element) => {
        if (props.isMulti) {
            let arr = []
            for (let x in element) {
                if (props.fullVal) { // Si necesita el valor completo
                    arr.push(element[x])
                } else { // entonces solo devolverme el val
                    arr.push(element[x].value)
                }
            }
            setValue(element)
            if (props.onChange) props.onChange(arr)
        } else {
            setValue(element)
            if (props.onChange && element !== null) {
                if (props.fullVal) { // Si necesita el valor completo
                    props.onChange(element)
                } else {
                    props.onChange(element.value)
                }
            } else {
                props.onChange(null)
            }
        }
    };

    const loadOptions = (inputValue, callback) => {
        let qs = 'name=' + inputValue
        let arr = []
        clearTimeout(debounce)
        setDebounce(setTimeout(() => {
            return GetSectorsPaginated(qs)
                .then(response => {
                    for (let x = 0; x < response.data.results.length; x++) {
                        let data = response.data.results[x]
                        arr.push({ value: data.id, label: data.name + ', ' + data.province.name })
                    }
                    return callback(arr)
                })
        }, 500));
    }
    return (
        <div className={props.sm ? "form-group-sm" : "form-group"}>
            {props.label ? <label htmlFor={props.id}>{props.label}: {props.required ? <span className="danger">*</span> : null}</label> : null}
            {props.help ? <Help id={props.id} help={props.help} /> : null}
            <AsyncSelect
                isMulti={props.isMulti}
                isClearable
                value={value}
                // defaultOptions
                // loadedOptions={props.defaultValue}
                openMenuOnClick={props.openMenuOnClick}
                loadOptions={loadOptions}
                isLoading={loading}
                placeholder={props.placeholder ? props.placeholder : 'Seleccionar'}
                isDisabled={loading}
                onChange={handleChange}
                classNamePrefix="react-select"
                closeMenuOnSelect={props.closeMenuOnSelect}
            />
        </div>
    )
}
export default withContext(SearchableSectors);