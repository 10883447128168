import React from 'react'
import { Route } from 'react-router-dom'
// Local import
import asyncComponent from '../../components/asyncComponent'
const List = asyncComponent(() => import('./list'))

function Subscriptions(props) {
    return (
        <div>
            <Route path="/subscriptions" exact component={List} />
        </div>
    )
}
export default Subscriptions
