import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@blueprintjs/core'

export default function AppLoader(props) {
    return (
        <>
            <LoadingContainer>
                <img
                    src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2020/4/K3RWuLGx9n-Logo-footer_1.png"
                    alt="Logo La Caja Rosa"
                />
            </LoadingContainer>
            <SpinnerContainer>
                <Spinner size={200} intent="success" />
            </SpinnerContainer>
        </>
    )
}
const LoadingContainer = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 99%;
    img {
        height: 55px;
        padding-bottom: 5px;
    }
`
const SpinnerContainer = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0.5;
`
