import React from 'react'
import { Row, Col } from 'reactstrap'
import { withRouter, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { withContext } from '../../context/withContext'

function Menu(props) {
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h4>Ajustes del Quiz</h4>
                    <NavLink activeClassName="active" exact to={`/settings`}>
                        Preferencias
                    </NavLink>
                    <NavLink activeClassName="active" exact to={`/settings/brands`}>
                        Marcas
                    </NavLink>
                    <NavLink activeClassName="active" exact to={`/settings/categories`}>
                        Categorías de Productos
                    </NavLink>
                    <NavLink activeClassName="active" exact to={`/settings/stores`}>
                        Tiendas
                    </NavLink>
                    <h4>Stripe</h4>
                    <NavLink activeClassName="active" exact to={`/settings/stripe`}>
                        API Keys
                    </NavLink>
                </Col>
            </Row>
        </Container>
    )
}
const Container = styled.div`
    .hot {
        padding: 1px 3px !important;
    }
    h4 {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
        margin-top: 5px;
    }
    a {
        display: block;
        box-sizing: border-box;
        color: #747678;
        text-decoration: none;
        display: flex;
        align-items: center;
        border-radius: 4px;
        white-space: normal;
        flex-shrink: 0;
        padding: 8px 16px;
        margin-bottom: 2px;
        min-height: 36px;
        transition: all 0.1s;
        :hover {
            background-color: rgba(38, 41, 44, 0.08);
            color: #26292c;
            text-decoration: none;
        }
    }
    a.active {
        font-weight: 600;
        background-color: rgba(49, 122, 226, 0.16);
        color: #317ae2;
    }
`
export default withRouter(withContext(Menu))
