import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
// LOCAL IMPORT
// Alterestate Version 2
import Members from '../page/members'
import Progress from '../page/progress'
import Dashboard from '../page/dashboard'
import Settings from '../page/settings'
import Subscriptions from '../page/subscriptions'
import Products from '../page/products'
import Payments from '../page/payments'
import PageNotFound from '../page/404'

class Content extends Component {
    render() {
        return (
            <div className="content" id="alterestate-container">
                <Switch>
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/members" component={Members} />
                    <Route path="/products" component={Products} />
                    <Route path="/progress" component={Progress} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/payments" component={Payments} />
                    <Route path="/subscriptions" component={Subscriptions} />
                    <Route component={PageNotFound} />
                </Switch>
            </div>
        )
    }
}

export default Content
