import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Dimmer, Avatar } from 'tabler-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { GetMembers } from '../../api/core'
import { withContext } from '../../context/withContext'

function Members(props) {
    const [data, setData] = useState([])
    const [loading, setLoader] = useState([])
    const token = props.context.token

    useEffect(() => {
        RetrieveData()
    }, [])

    const RetrieveData = async () => {
        try {
            let response = await GetMembers(token, '?page_size=4&sort=-timestamp')
            setLoader(false)
            setData(response.data.results)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Container>
            <Dimmer loading={loading}>
                <table className="table card-table table-striped table-vcenter">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Miembro</th>
                            <th>Teléfono 1</th>
                            <th>Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((e, key) => (
                            <tr key={key}>
                                <td className="w-1">
                                    {e.photo ? (
                                        <Avatar size="xs" imageURL={e.photo} />
                                    ) : (
                                        <span className="avatar">MM</span>
                                    )}
                                </td>
                                <td className="text-nowrap">
                                    <Link to={`/members/view/${e.uid}`}>
                                        {e.first_name} {e.last_name}
                                    </Link>
                                </td>
                                <td className="text-nowrap">
                                    {e.phone ? (
                                        <>
                                            {e.phone}{' '}
                                            <span className="ml-1 low-opacity">
                                                <a href={`https://wa.me/1${e.phone}`} target="_blank">
                                                    <i class="fab fa-whatsapp"></i>
                                                </a>
                                            </span>
                                        </>
                                    ) : (
                                        <span className="low-opacity">N/A</span>
                                    )}
                                </td>
                                <td className="text-nowrap">{moment(e.timestamp).format('DD-MM-YYYYY hh:mm A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Dimmer>
        </Container>
    )
}
const Container = styled.div``
export default withContext(Members)
