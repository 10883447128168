import { API } from '../utils/config'

//-------------------------//
//      LOCATION
//-------------------------//
export function GetCountries() {
    return API('/countries/')
}
export function GetProvinces() {
    return API('/provinces/149/')
}
export function GetCities(qs) {
    return API(`/cities/${qs}`)
}
export function GetSectors(qs) {
    return API(`/sectors/${qs}`)
}
export function GetSectorsPaginated(qs) {
    return API('/sectors/paginated/?' + qs)
}
//-------------------------//
//      USERS
//-------------------------//
export function GetUsers(token, qs) {
    return API(`/users/${qs}`, { headers: { Authorization: 'Token ' + token } })
}
export function GetMembers(token, qs) {
    return API(`/members/${qs}`, { headers: { Authorization: 'Token ' + token } })
}
export function GetFeedbacks(token, qs) {
    return API(`/feedbacks/${qs}`, { headers: { Authorization: 'Token ' + token } })
}
export function ViewMemberProfile(token, uid) {
    return API(`/members/admin/view/${uid}/`, { headers: { Authorization: 'Token ' + token } })
}
export function PreviewMember(token, pk) {
    return API(`/members/preview/${pk}/`, { headers: { Authorization: 'Token ' + token } })
}
//-------------------------//
//      SHIPMENTS
//-------------------------//
export function GetShipments(token, qs) {
    return API(`/shipments/${qs ? `?${qs}` : ''}`, { headers: { Authorization: 'Token ' + token } })
}
export function NewShipment(token, data) {
    return API.post(`/shipments/new/`, data, { headers: { Authorization: 'Token ' + token } })
}
export function UpdateShipment(token, id, data) {
    return API.patch(`/shipments/${id}/edit`, data, { headers: { Authorization: 'Token ' + token } })
}

//-------------------------//
//      AUTHENTICATION
//-------------------------//
export function ValidateToken(token) {
    return API.post('/validateToken/', { token: token })
}
export function UpdateUser(token, uid, data) {
    if (data) {
        return API.patch('/members/edit/' + uid + '/', data, { headers: { Authorization: 'Token ' + token } })
    } else {
        return API('/members/edit/' + uid + '/', { headers: { Authorization: 'Token ' + token } })
    }
}
export function UpdatePassword(token, uid, data) {
    return API.patch('/members/change-password/' + uid + '/', data, {
        headers: { Authorization: 'Token ' + token },
    })
}
export function DeleteMember(token, uid, data) {
    return API.post('/members/delete/' + uid + '/', data, { headers: { Authorization: 'Token ' + token } })
}
export function HandleUserActivation(token, uid, data) {
    return API.patch('/members/activation/' + uid + '/', data, {
        headers: { Authorization: 'Token ' + token },
    })
}
