import React from 'react';
import Help from '../Help';

export default function Select(props) {
    return (
        props.columns === 2 ?
                <div className={props.sm ? "form-group-sm" : "form-group"}>
                    <div className="row">
                        <div className="col-xs-12 col-md-12 col-lg-4 text-right-lg">
                            {props.label ? <label className="control-label" htmlFor={props.id}>{props.label}: {props.required ? <span className="danger">*</span> : null}</label> : null}
                            {props.help ? <Help id={props.id} help={props.help} /> : null}
                        </div>
                        <div className="col-xs-12 col-md-12 col-lg-6">
                            <select
                                className={props.className + " form-control custom-select"}
                                required={props.required}
                                style={props.style}
                                id={props.id}
                                onChange={props.onChange}
                                disabled={props.disabled}
                                value={props.value}
                                name={props.id}>
                                {props.value === null || props.value === undefined &&
                                    <>
                                        <option value="" style={{ display: 'none' }}>{props.customPlaceholder ? props.customPlaceholder : 'Seleccionar'}</option>
                                        <option disabled>{props.customPlaceholder ? props.customPlaceholder : 'Seleccionar'}</option>
                                    </>
                                }
                                {props.children}
                            </select>
                        </div>
                    </div>
                </div>
            :
            <div className={props.inline ? props.sm ? "d-inline form-group-sm" : "form-group" : props.sm ? "form-group-sm" : "form-group"}>
                {props.label ? <label htmlFor={props.id}>{props.label}: {props.required ? <span className="danger">*</span> : null}</label> : null}
                {props.help ? <Help id={props.id} help={props.help} /> : null}
                <select
                    className={props.className + " form-control custom-select"}
                    required={props.required}
                    style={props.style}
                    id={props.id}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    value={props.value}
                    name={props.id}>
                    {props.value === null || props.value === undefined &&
                        <>
                            <option value="" style={{ display: 'none' }}>{props.customPlaceholder ? props.customPlaceholder : 'Seleccionar'}</option>
                            <option disabled>{props.customPlaceholder ? props.customPlaceholder : 'Seleccionar'}</option>
                        </>
                    }
                    {props.children}
                </select>
            </div>
    )
}