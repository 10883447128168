import React from 'react'
import { Route } from 'react-router-dom'
// Local import
import asyncComponent from '../../components/asyncComponent'
const List = asyncComponent(() => import('./list'))

function Payments(props) {
    return (
        <div>
            <Route path="/payments" exact component={List} />
        </div>
    )
}
export default Payments
