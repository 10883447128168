import React from 'react'
import { Route } from 'react-router-dom'
// Local import
import asyncComponent from '../../components/asyncComponent'
const ListMembers = asyncComponent(() => import('./list'))
const MemberProfile = asyncComponent(() => import('./profile'))

function Members(props) {
    return (
        <div>
            <Route path="/members" exact component={ListMembers} />
            <Route path="/members/view/:uid" exact component={MemberProfile} />
        </div>
    )
}
export default Members
