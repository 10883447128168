import React from 'react';
import Help from '../Help';
import NumberFormat from 'react-number-format';

export default function Input(props) {
    const onKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\.|-/.test(keyValue))
            event.preventDefault();
    }
    return (
        props.columns === 2 ?
            <div className={props.sm ? "form-group-sm" : "form-group"}>
                <div className="row">
                    <div className="col-xs-12 col-md-12 col-lg-4 text-right-lg">
                        {props.label ? <label className="control-label" htmlFor={props.id}>{props.label}: {props.required ? <span className="danger">*</span> : null}</label> : null}
                        {props.help ? <Help id={props.id} help={props.help} /> : null}
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-6">
                        <div className={props.icon ? "input-icon" : null}>
                            {props.iconFirst && props.icon ? <span className="input-icon-addon"><i className={props.icon}></i></span> : null}
                            {props.formattedVal ?
                                <NumberFormat
                                    {...props}
                                    onKeyPress={props.normalizenumber ? onKeyPress : undefined}
                                    className={props.className ? props.className + ' form-control' : 'form-control'} />
                                :
                                <input
                                    {...props}
                                    onKeyPress={props.normalizenumber ? onKeyPress : undefined}
                                    className={props.className ? props.className + ' form-control' : 'form-control'} />
                            }
                            {props.iconLast && props.icon ? <span className="input-icon-addon"><i className={props.icon}></i></span> : null}
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className={props.sm ? "form-group-sm" : "form-group"}>
                {props.label ? <label htmlFor={props.id}>{props.label}: {props.required ? <span className="danger">*</span> : null}</label> : null}
                {props.help ? <Help id={props.id} help={props.help} /> : null}
                <div className={props.icon ? "input-icon" : null}>
                    {props.iconFirst && props.icon ? <span className="input-icon-addon"><i className={props.icon}></i></span> : null}
                    {props.formattedVal ?
                        <NumberFormat
                            {...props}
                            onKeyPress={props.normalizenumber ? onKeyPress : undefined}
                            className={props.className ? props.className + ' form-control' : 'form-control'} />
                        :
                        <input
                            {...props}
                            onKeyPress={props.normalizenumber ? onKeyPress : undefined}
                            className={props.className ? props.className + ' form-control' : 'form-control'} />
                    }
                    {props.iconLast && props.icon ? <span className="input-icon-addon"><i className={props.icon}></i></span> : null}
                </div>
            </div>
    )
}
