import React, { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Spinner, Position, Menu, MenuItem, Popover, Alignment, Navbar, Button } from '@blueprintjs/core'
import Cookies from 'universal-cookie'
import Login from './page/Login/index'
import { Modal, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'
// Local imports
import Content from './Layouts/Content'
import { withContext } from './context/withContext'
import AppLoader from './components/AppLoader'

const cookies = new Cookies()

function Principal(props) {
    const isLogged = props.context.isLogged
    const loadingApp = props.context.loadingApp
    const user = props.user.user
    const menu = [
        { to: '/', text: 'Escritorio', icon: 'home' },
        { to: '/members', text: 'Miembros', icon: 'database' },
        // { to: '/products', text: 'Productos', icon: 'barcode' },
        { to: '/subscriptions', text: 'Suscripciones', icon: 'barcode' },
        { to: '/payments', text: 'Pagos', icon: 'dollar' },
        // { to: '/settings', text: 'Ajustes', icon: 'build' },
    ]
    const [modal, toggle] = useState(false)

    const handleLogout = () => {
        toggle(true)
        cookies.remove('token')
        let state = {
            token: null,
            isLogged: false,
            user: {},
        }
        setTimeout(() => {
            toggle(false)
            props.updateContextVal(state)
        }, 2000)
    }
    return (
        <BrowserRouter>
            {loadingApp ? (
                <AppLoader />
            ) : isLogged ? (
                <div>
                    <Container>
                        <Navbar className="bp3-dark">
                            <Navbar.Group align={Alignment.LEFT}>
                                <Navbar.Heading>
                                    <strong>La Caja Rosa</strong>
                                </Navbar.Heading>
                                <Navbar.Divider />
                                {menu.map((e, key) => (
                                    <Link to={e.to} key={key}>
                                        <Button className="bp3-minimal" icon={e.icon} text={e.text} />
                                    </Link>
                                ))}
                            </Navbar.Group>
                            <Navbar.Group align={Alignment.RIGHT}>
                                <Popover
                                    content={
                                        <PopoverContent
                                            updateContextVal={props.updateContextVal}
                                            handleLogout={handleLogout}
                                        />
                                    }
                                    position={Position.BOTTOM_RIGHT}
                                    target={
                                        <Button
                                            className="bp3-minimal"
                                            icon="user"
                                            text={`${user.first_name} ${user.last_name}`}
                                        />
                                    }
                                />
                            </Navbar.Group>
                        </Navbar>
                        <Modal isOpen={modal}>
                            <ModalBody>
                                <div className="card-body text-center">
                                    <center>
                                        <div className="mt-6 mb-6">
                                            <Spinner size={50} />
                                            <h3 className="mt-6">Estamos cerrando la sesión...</h3>
                                            <p>
                                                Esperamos volverte a ver pronto <span role="image">🤗</span>
                                            </p>
                                        </div>
                                    </center>
                                </div>
                            </ModalBody>
                        </Modal>
                    </Container>
                    <div>
                        <Content user={props.user} />
                    </div>
                </div>
            ) : (
                <Login />
            )}
        </BrowserRouter>
    )
}

function PopoverContent(props) {
    return (
        <React.Fragment>
            <Menu>
                <MenuItem icon="log-out" text="Cerrar Sesión" onClick={props.handleLogout} />
            </Menu>
        </React.Fragment>
    )
}

const Container = styled.div`
    a {
        color: #fff;
        text-decoration: none;
        :hover {
            text-decoration: none;
        }
    }
`
export default withContext(Principal)
