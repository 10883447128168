import React, { useEffect, useState } from 'react'
import { StatsCard } from 'tabler-react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
// Local imports
import LatestMembers from '../../components/elements/LatestMembers'
import Feedbacks from '../../components/members/tables/feedbacks'
import { withContext } from '../../context/withContext'
import { API } from '../../utils/config'

function Dashboard(props) {
    const [data, setData] = useState({})
    const user = props.context.user

    useEffect(() => {
        API('/reports/dashboard/').then((response) => {
            console.log(response.data)
            setData(response.data)
        })
    }, [])

    return (
        <Container className="container pt-6 pb-4">
            <h3>Bienvenida de vuelta {user.first_name}!</h3>
            <div className="row">
                <Col xs={12} md={3}>
                    <StatsCard
                        layout={1}
                        movement={+0}
                        total={<NumberFormat value={data.members} thousandSeparator={true} displayType={'text'} />}
                        label="Miembros"
                    />
                </Col>
                <Col xs={12} md={3}>
                    <StatsCard
                        layout={1}
                        movement={+0}
                        total={<NumberFormat value={data.subs_active} thousandSeparator={true} displayType={'text'} />}
                        label="Suscripciones Activas"
                    />
                </Col>
                <Col xs={12} md={3}>
                    <StatsCard
                        layout={1}
                        movement={+0}
                        total={
                            <NumberFormat value={data.subs_inactive} thousandSeparator={true} displayType={'text'} />
                        }
                        label="Suscripciones Suspendidas"
                    />
                </Col>
                <Col xs={12} md={3}>
                    <StatsCard
                        layout={1}
                        movement={+0}
                        total={<NumberFormat value={data.feedbacks} thousandSeparator={true} displayType={'text'} />}
                        label="Feedbacks"
                    />
                </Col>
            </div>
            <Row className="row-cards row-deck">
                <Col xs={12} md={6}>
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Nuevos Miembros ✨</div>
                        </div>
                        <div className="table-responsive">
                            <LatestMembers params={`sort=-timestamp`} />
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Últimos Feedbacks 💖</div>
                        </div>
                        <div className="table-responsive">
                            <Feedbacks params={`sort=-timestamp`} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
const Container = styled.div`
    .text-yellow {
        color: #fff !important;
    }
`
export default withContext(Dashboard)
