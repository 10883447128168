import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import { Alert } from 'reactstrap'
import { Button } from 'tabler-react'
import { withRouter } from 'react-router-dom'
// Local import
import Logo from '../../static/images/alterestate-full.png'
import { withContext } from '../../context/withContext'
import { Input } from '../../components/FormGroups'
import { API } from '../../utils/config'
const cookies = new Cookies()

function Login(props) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [alert, showAlert] = useState(false)
    const [loading, showLoading] = useState(false)
    const handleLogin = (e) => {
        e.preventDefault()
        showAlert(false)
        showLoading(true)
        API.post('/login/', { username: username, password: password })
            .then((response) => {
                cookies.set('toggle', false, { path: '/' })
                cookies.set('token', response.data.token, { path: '/' })
                let data = {
                    user: response.data.user,
                    token: response.data.token,
                    isLogged: true,
                }
                props.updateContextVal(data)
                showLoading(false)
                this.props.history.push('/')
            })
            .catch((err) => {
                showAlert(true)
                showLoading(false)
            })
    }
    return (
        <div className="page">
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div className="col col-login mx-auto">
                            <div className="text-center mb-6">
                                <img
                                    src={
                                        'https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2020/4/K3RWuLGx9n-Logo-footer_1.png'
                                    }
                                    style={{ height: '100px', paddingTop: '1rem' }}
                                    alt="Alterestate Logo"
                                />
                            </div>
                            <form className="card" onSubmit={handleLogin}>
                                <div className="card-body p-6">
                                    <div className="card-title">Inciar Sesión</div>
                                    <Input
                                        required
                                        type="text"
                                        label="Email"
                                        id="username"
                                        placeholder="Email Address"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value.toLowerCase().replace(' ', ''))}
                                    />
                                    <Input
                                        required
                                        type="password"
                                        label="Password"
                                        id="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="form-footer">
                                        <Button loading={loading} color="primary" className="btn-block" type="submit">
                                            Acceder
                                        </Button>
                                    </div>
                                    {alert && (
                                        <Alert color="danger" className="mt-4">
                                            <center>¡Los datos son incorrectos!</center>
                                        </Alert>
                                    )}
                                </div>
                            </form>
                            <center>La Caja Rosa (c) - Todos los derechos reservados.</center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(withContext(Login))
