import React from 'react'
import { Route } from 'react-router-dom'
// Local import
import asyncComponent from '../../components/asyncComponent'
const List = asyncComponent(() => import('./list'))
const Details = asyncComponent(() => import('./details'))

function Products(props) {
    return (
        <div>
            <Route path="/products" exact component={List} />
            <Route path="/products/view/:uid" exact component={Details} />
        </div>
    )
}
export default Products
