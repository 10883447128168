import axios from 'axios'

const baseURL =
    process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1' : 'https://api.lacajarosa.com/api/v1'

const stripeKEY =
    process.env.NODE_ENV === 'development'
        ? 'sk_test_w4iIUnSoiZqAIru3AaQilk9x00g9yswRgA'
        : 'sk_live_P7ufaHeoUlIqDTX2eTzbGmLv00YtVKvjRQ'

export const API = axios.create({
    baseURL: baseURL,
})

export const STRIPE_API = axios.create({
    baseURL: 'https://api.stripe.com/v1/',
    headers: {
        Authorization: `Bearer ${stripeKEY}`,
    },
})
