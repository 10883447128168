import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { GetFeedbacks } from '../../../api/core'
import { withContext } from '../../../context/withContext'

function Feedbacks(props) {
    const [data, setData] = useState([])
    const token = props.context.token

    useEffect(() => {
        GetFeedbacks(token, `?${props.params}`).then((response) => {
            setData(response.data.results)
        })
    }, [])

    return (
        <Container>
            <table className="table card-table table-striped table-vcenter">
                <thead>
                    <tr>
                        <th>Caja</th>
                        <th>Fecha</th>
                        <th>Puntuación</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((e, key) => (
                        <tr key={key}>
                            <td>Caja {moment(e.timestamp).format('M')}</td>
                            <td className="text-nowrap">{moment(e.timestamp).format('DD-MM-YYYY')}</td>
                            <td>
                                <span className="warning">
                                    {e.rate === 1 && (
                                        <React.Fragment>
                                            <i className="fas fa-star"></i>
                                        </React.Fragment>
                                    )}
                                    {e.rate === 2 && (
                                        <React.Fragment>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </React.Fragment>
                                    )}
                                    {e.rate === 3 && (
                                        <React.Fragment>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </React.Fragment>
                                    )}
                                    {e.rate === 4 && (
                                        <React.Fragment>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </React.Fragment>
                                    )}
                                    {e.rate === 5 && (
                                        <React.Fragment>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </React.Fragment>
                                    )}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    )
}
const Container = styled.div``
export default withContext(Feedbacks)
