import React from 'react'
import { Route } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
// Local import
import asyncComponent from '../../components/asyncComponent'
import SettingsMenu from '../../components/settings/SettingsMenu'
const List = asyncComponent(() => import('./settings'))
const Categories = asyncComponent(() => import('./categories'))
const Stores = asyncComponent(() => import('./stores'))
const Brands = asyncComponent(() => import('./brands'))
const Fragances = asyncComponent(() => import('./fragances'))

function Settings(props) {
    return (
        <div className="container pt-6">
            <Row>
                <Col xs={12} md={2}>
                    <SettingsMenu />
                </Col>
                <Col xs={12} md={10}>
                    <Route path="/settings" exact component={List} />
                    <Route path="/settings/categories" exact component={Categories} />
                    <Route path="/settings/stores" exact component={Stores} />
                    <Route path="/settings/brands" exact component={Brands} />
                    <Route path="/settings/fragances" exact component={Fragances} />
                </Col>
            </Row>
        </div>
    )
}
export default Settings
